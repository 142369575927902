import {project} from '@ermazd/common'
import {EmblaOptionsType} from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import Image from 'next/image'
import {s} from 'sanity-typed-schema-builder'

import {athomImageBuilder} from '../lib/client'

type ProjectMedia = s.infer<typeof project>['media']

interface CarouselProps {
    images: ProjectMedia
    options: EmblaOptionsType
    className?: string
}

export default function Carousel({images, options, className}: CarouselProps) {
    const [emblaRef] = useEmblaCarousel(options, [Autoplay({delay: 5000})])

    return (
        <div className={`overflow-hidden ${className ?? ''}`} ref={emblaRef}>
            <div className="flex">
                {images?.map((image, index) => {
                    return (
                        <div key={index} className="relative flex-c">
                            <Image
                                src={athomImageBuilder.image(image).url()}
                                width={1440}
                                height={900}
                                className="rounded-3xl"
                                alt={image?.caption ?? 'Carousel Image'}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
