import * as React from 'react'

const SvgInstagram = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.363 37.363" {...props}>
        <g data-name="Group 168" fill="#797979">
            <path
                data-name="Path 1190"
                d="M23.053 10.222h-8.746a4.524 4.524 0 0 0-4.519 4.518v8.746A4.525 4.525 0 0 0 14.307 28h8.746a4.524 4.524 0 0 0 4.518-4.519V14.74a4.523 4.523 0 0 0-4.518-4.518M18.77 24.508a5.394 5.394 0 1 1 5.394-5.394 5.4 5.4 0 0 1-5.394 5.394m5.709-9.868a1.284 1.284 0 1 1 1.28-1.284 1.284 1.284 0 0 1-1.28 1.284"
            />
            <path
                data-name="Path 1191"
                d="M18.77 15.332a3.455 3.455 0 1 0 3.455 3.455 3.459 3.459 0 0 0-3.455-3.455"
            />
            <path
                data-name="Path 1192"
                d="M27.589 0H9.773A9.8 9.8 0 0 0 0 9.774V27.59a9.8 9.8 0 0 0 9.773 9.773h17.816a9.8 9.8 0 0 0 9.774-9.773V9.774A9.8 9.8 0 0 0 27.589 0m2.043 23.055a6.587 6.587 0 0 1-6.58 6.579h-8.743a6.587 6.587 0 0 1-6.58-6.579v-8.746a6.587 6.587 0 0 1 6.58-6.579h8.744a6.587 6.587 0 0 1 6.58 6.579Z"
            />
        </g>
    </svg>
)

export default SvgInstagram
