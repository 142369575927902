import {ATHOM_SANITY_ID, NewsArticle, Project, SANITY_API_VERSION} from '@ermazd/common'
import imageUrlBuilder from '@sanity/image-url'
import {createClient, groq} from 'next-sanity'

import {AboutPage, HomePage, PrivacyPolicy, TermsOfService} from '../schemas'

export type HomePageQueryType = Omit<HomePage, 'carouselProject' | 'featuredProjects'> & {
    carouselProject: Project
    featuredProjects: Project[]
}

export const athomClient = createClient({
    projectId: ATHOM_SANITY_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    apiVersion: SANITY_API_VERSION,
    useCdn: false,
    token: process.env.ATHOM_SANITY_TOKEN,
})

export const athomImageBuilder = imageUrlBuilder(athomClient)

export async function getHomePage(): Promise<HomePageQueryType> {
    return athomClient.fetch(groq`
    *[_type == 'homePage' && _id == 'homePage'][0]{...,"carouselProject": carouselProject->{...}, "featuredProjects": featuredProjects[]->{...}}`)
}

export async function getAboutPage(): Promise<AboutPage> {
    return athomClient.fetch(groq`*[_type == 'aboutPage' && _id == 'aboutPage'][0]`)
}

export async function getPrivacyPolicy(): Promise<PrivacyPolicy> {
    return athomClient.fetch(groq`*[_type == 'privacyPolicy' && _id == 'privacyPolicy'][0]`)
}

export async function getTermsOfService(): Promise<TermsOfService> {
    return athomClient.fetch(groq`*[_type == 'termsOfService' && _id == 'termsOfService'][0]`)
}

export async function getProjects(): Promise<Project[]> {
    return athomClient.fetch(groq`*[_type == 'project' && visible == true] | order(featured desc)`)
}

export async function getProjectBySlug(slug: string): Promise<Project> {
    return athomClient.fetch(groq`*[_type == 'project' && slug.current == $slug][0]`, {slug})
}

export async function getNewsArticles(): Promise<NewsArticle[]> {
    return athomClient.fetch(groq`*[_type == 'newsArticle']`)
}
