import Image from 'next/image'
import Link from 'next/link'

import logo from '../assets/logo.png'
import SvgFacebook from './SvgFacebook'
import SvgInstagram from './SvgInstagram'

export interface FooterProps {
    logoHidden?: boolean
}

export default function Footer({logoHidden = false}: FooterProps) {
    return (
        <div className="container px-12 mx-auto mt-12 bg-black lg:mt-40">
            <footer className="flex flex-col justify-between py-8 text-white border-t lg:flex-row border-slate-100">
                <div className="flex flex-row justify-start w-1/2 mb-4 lg:mb-0">
                    <div className="w-40">
                        <Image
                            src={logo}
                            alt="Athom logo"
                            className={`${logoHidden ? 'invisible' : ''}`}
                        />
                    </div>
                </div>
                <nav>
                    <ul className="-mt-2">
                        <li className="mt-2 mb-4 uppercase lg:mb-0">
                            <Link href="/projects">Work</Link>
                        </li>
                        <li className="mt-2 mb-4 uppercase lg:mb-0">
                            <Link href="/about">About</Link>
                        </li>
                        <li className="mt-2 mb-4 uppercase lg:mb-0">
                            <Link href="/privacy">Privacy Policy</Link>
                        </li>
                        <li className="mt-2 mb-4 uppercase lg:mb-0">
                            <Link href="/terms">Terms of Use</Link>
                        </li>
                    </ul>
                </nav>
                <div className="flex flex-col justify-between">
                    <p className="pb-3 text-base">
                        mail@athompictures.com
                        <br />
                        C-2/6 Safdarjung Development Area
                        <br />
                        New Delhi-110016
                        <br />
                        +91 98712 65440
                    </p>
                    <div className="flex flex-row mt-4">
                        <a
                            href="https://facebook.com/athompictures"
                            className="block w-8 mx-4"
                            aria-label="Athom Pictures on Facebook"
                        >
                            <SvgFacebook />
                        </a>
                        <a
                            href="https://instagram.com/athompictures"
                            className="block w-8 mx-4"
                            aria-label="Athom Pictures on Instagram"
                        >
                            <SvgInstagram />
                        </a>
                    </div>
                    <p className="mt-12 text-xs uppercase">
                        TM & © {new Date().getFullYear()} Brahm Media Pvt Ltd. All Rights Reserved.
                    </p>
                </div>
            </footer>
        </div>
    )
}
