import * as React from 'react'

const SvgFacebook = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.363 37.362" {...props}>
        <path
            data-name="Path 1193"
            d="M37.363 9.773A9.8 9.8 0 0 0 27.59-.004H9.774A9.8 9.8 0 0 0 0 9.773v17.816a9.8 9.8 0 0 0 9.774 9.773h5.989V24.696H11.02v-5.4h4.743v-4.117c0-4.681 2.789-7.269 7.057-7.269a28.681 28.681 0 0 1 4.18.366v4.6h-2.355a2.7 2.7 0 0 0-3.045 2.915v3.5h5.181l-.828 5.4H21.6v12.671h5.99a9.8 9.8 0 0 0 9.773-9.773Z"
            fill="#797979"
        />
    </svg>
)

export default SvgFacebook
