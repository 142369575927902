import {motion} from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import {useState} from 'react'

import logo from '../assets/logo.png'
import title from '../assets/title.png'

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className="relative">
            <header className="container z-10 flex flex-row items-center justify-between px-12 py-6 mx-auto bg-black">
                <Link href="/" className="w-40">
                    <Image src={title} alt="Athom" />
                </Link>

                <button
                    className={`w-5 h-5 rounded-full z-50 ${
                        isOpen ? 'bg-white' : 'bg-brand-yellow hover:bg-white'
                    }`}
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                    aria-label="Toggle Navigation"
                />
            </header>
            <motion.nav
                className="w-screen h-screen z-10 fixed top-0 bg-black"
                animate={{y: isOpen ? 0 : -9999}}
                transition={{type: 'tween', duration: 0.6}}
                initial={{y: -9999}}
            >
                <div className="container flex flex-row items-center justify-between w-full h-full mx-auto">
                    <div className="flex-row items-center justify-center hidden w-1/2 lg:flex">
                        <div className="w-96">
                            <Image src={logo} alt="Athom Logo" />
                        </div>
                    </div>
                    <ul className="w-full text-[52px] text-center uppercase lg:w-1/2">
                        <motion.li whileHover={{color: '#D5B11A'}} className="my-12">
                            <Link href="/projects">Work</Link>
                        </motion.li>
                        <motion.li whileHover={{color: '#D5B11A'}} className="my-12">
                            <Link href="/about">About</Link>
                        </motion.li>
                    </ul>
                </div>
            </motion.nav>
        </div>
    )
}
