import {athomProjectTypeLegend, hasImageMedia} from '@ermazd/common'
import {PortableText, PortableTextReactComponents} from '@portabletext/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import {useMemo} from 'react'

import {athomImageBuilder, getHomePage, HomePageQueryType} from '@/lib/client'

import Carousel from '../components/Carousel'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

interface HomeProps {
    page: HomePageQueryType
}

export default function Home({page}: HomeProps) {
    const [emblaRef] = useEmblaCarousel({loop: true, startIndex: 1}, [Autoplay({delay: 5000})])
    const displayCarousel =
        page.carouselProject !== undefined && hasImageMedia(page.carouselProject)
    const taglineClass = classNames(
        'px-8 lg:px-0 block w-full mb-32 text-[22px] leading-snug lg:text-[52px]',
        {'mt-4 lg:mt-8': displayCarousel}
    )
    const taglinePtComponents: Partial<PortableTextReactComponents> = useMemo(() => {
        return {
            block: {
                normal: ({children}) => <p className={taglineClass}>{children}</p>,
            },
            marks: {
                strong: ({children}) => <span className="font-bold">{children}</span>,
            },
        }
    }, [taglineClass])
    return (
        <>
            <Head>
                <title>ATHOM</title>
            </Head>
            <Navbar />
            <main className="container flex flex-col lg:px-[100px] mx-auto lg:mt-4">
                {displayCarousel && (
                    <Carousel
                        images={page.carouselProject.media}
                        options={{loop: true}}
                        className="max-h-[213px] sm:max-h-[283px] md:max-h-[340px] lg:max-h-[365px] xl:max-h-[479px] 2xl:max-h-[592px]"
                    />
                )}
                <PortableText value={page.content} components={taglinePtComponents} />
                <div className="flex flex-col px-8 mb-8 lg:px-0">
                    <div className="flex flex-row self-end justify-center w-full mb-4 text-[18px] lg:text-[24px] font-medium uppercase lg:justify-end lg:mb-1 group">
                        <Link
                            href="/projects"
                            className="flex flex-row-reverse items-center transition-opacity lg:flex-row hover:text-brand-yellow"
                        >
                            See all projects
                            <span className="inline-block w-4 h-4 mr-6 lg:mr-0 lg:ml-6  bg-white rounded-full group-hover:bg-brand-yellow"></span>
                        </Link>
                    </div>
                    {page?.featuredProjects?.map((project, index) => {
                        return (
                            <div className="w-full" key={index}>
                                {hasImageMedia(project) && (
                                    <div className="w-full overflow-hidden" ref={emblaRef}>
                                        <div className="flex">
                                            {project.media?.map((image, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`relative flex-c lg:flex-cc ${
                                                            index === 0 ? 'lg:mr-2' : 'lg:mx-2'
                                                        }`}
                                                    >
                                                        <Image
                                                            src={athomImageBuilder
                                                                .image(image)
                                                                .url()}
                                                            className="rounded-3xl"
                                                            width={1440}
                                                            height={900}
                                                            alt={
                                                                image.caption ??
                                                                `${project.title} banner image`
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={classNames('w-full mt-4', {
                                        'mb-4 lg:mb-8': index === page.featuredProjects.length - 1,
                                        'mb-12 lg:mb-24':
                                            index !== page.featuredProjects.length - 1,
                                    })}
                                >
                                    <Link
                                        href={`/projects/${project.slug.current}`}
                                        className="mt-3 text-[16px] lg:text-[32px] font-normal leading-normal uppercase  transition-opacity"
                                    >
                                        <span className="hover:text-brand-yellow">
                                            {project.title}&nbsp;/&nbsp;
                                            {dayjs(project.releaseDate).format('YYYY')} <br />
                                        </span>
                                        <span className="text-[14px] lg:text-[18px] uppercase opacity-50">
                                            {athomProjectTypeLegend[project.type]}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </main>
            <Footer />
        </>
    )
}

export async function getStaticProps() {
    const page = await getHomePage()
    return {
        props: {
            page,
        },
    }
}
